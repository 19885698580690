import { Link } from "@inertiajs/react";
import sampleImage from '@assets/woman-in-gown.png';
import { Card } from "@/Components/ui/card";
import { Separator } from "@/Components/ui/separator";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Button } from "@/Components/ui/button";

const fetchLimitedOffers = async () => {
    const { data } = await window.axios.get(route('predesign-products.paginate', { system: 1, page: 1, per_page: 4, order: 'random:desc' }));
    return data?.data || [];
}

export default function LimitedOffer() {
    const { data } = useQuery({
        queryKey: ['limited-offers'],
        queryFn: () => fetchLimitedOffers()
    })

    const list = useMemo(() => data?.slice(0, 3), [data]);

    return <div className="relative justify-center items-center shadow-xl shadow-spread-2  mt-8 flex flex-col gap-4 rounded-2xl bg-cyan-600">
        <div className="max-w-7xl relative w-full flex md:flex-row flex-col justify-center gap-y-4 md:gap-x-6 gap-x-4 items-stretch h-full pt-6">
            <div data-aos="fade-up" className="h-full flex-grow md:w-1/3 w-full flex flex-col justify-start pt-8 gap-4 items-start">
                <h1 className="text-white font-semibold md:text-3xl text-2xl md:ml-0 ml-4 text-left">Limited Time Offer!</h1>
                <Separator className="h-[3px] rounded-full" />
                <p className="text-white font-semibold md:text-xl text-md md:text-left text-center w-full">Grab these amazing deals before they run out of stock!</p>
            </div>

            <div className="h-full md:w-2/3 w-full p-4 grid gap-4 md:grid-cols-3 grid-cols-2 items-stretch">
                {list?.map((item, index) => (
                    <Card data-aos="fade-up" key={index} className="flex flex-col justify-center rounded-2xl shadow-xl shadow-spread-2 items-center gap-4">
                        <div className="w-full max-h-[300px] flex justify-center items-center p-2 rounded-2xl bg-white">
                            <img src={item.previews?.[0]?.url_thumb} className="w-full h-full object-contain rounded-2xl" />
                        </div>
                        <div className="pb-4 w-full text-center text-primary font-semibold flex flex-col whitespace-nowrap">
                            <Link href={route('predesign-products.view', { uuid: item.uuid })}>
                                <Button className="font-semibold" variant="theme">View Options</Button>
                            </Link>
                        </div>
                    </Card>
                ))}
            </div>
        </div>

        <div data-aos="fade-up" className="w-full h-full flex flex-col justify-end pb-4 items-center">
            <Link href={route('home.predesign-products', { category: "all" })} className="text-white hover:underline hover:text-primary font-semibold px-8 py-2 rounded-2xl">View All</Link>
        </div>
    </div>
}
